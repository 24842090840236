import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Sidebar from '../../Components/Includes/Sidebar'
import { useNavigate, useParams } from 'react-router-dom';
import profilePic from "../../assets/images/profile-pic.jpg";
import ImgCreateVideo from '../../assets/images/channel-create.png';
import { authHttps, getToken, getUser } from "../../auth/AuthUser";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import CardMedia from '@mui/material/CardMedia';
import { formatDistanceToNow } from 'date-fns';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import channelbanner from "../../assets/images/channels4_banner.jpg";



import VideoListLoading from '../../Components/LoadingPages/VideoListLoading';
import { ShortsRoute, VideoRoute, handleShareLong, handleShareShort } from '../../utils/routeUtils';
import VideosNotFound from '../../Components/ErrorPages/VideosNotFound';
import ShortsListingLoading from '../../Components/LoadingPages/ShortsListingLoading';
import ShimmerLoading from '../../Components/LoadingPages/ShimmerLoading';
import EditVideo from '../../Components/Functions/EditVideo';
import { APP_URL } from '../../app/config';
import Header from '../../Components/Includes/Header';
import SidebarIcon from '../../Components/Includes/SidebarIcon';

const TimeAgo = ({ date }) => {
	// Assuming `date` is a string in the format 'YYYY-MM-DDTHH:mm:ss.sssZ'
	const parsedDate = new Date(date);

	const timeAgo = formatDistanceToNow(parsedDate, { addSuffix: true });

	return <span>{timeAgo}</span>;
};

const formatViewsCount = (count) => {
	if (count < 1000) {
		return count.toString();
	} else if (count < 1000000) {
		return `${(count / 1000).toFixed(1)}K`;
	} else {
		return `${(count / 1000000).toFixed(1)}M`;
	}
};


let apiRequestMade = false;


function Channel() {


	const http = authHttps();

	let userData = getUser();
	let token = getToken();



	const [currentVideo, setCurrentVideo] = useState(null);
	const [editVideo, setEditVideo] = useState(null);
	const [allVideo, setallVideo] = useState([]);
	const [shortVideo, setshortVideo] = useState([]);
	const [loadingvideos, setloadingvideos] = useState(true);
	const [loadingvideos2, setloadingvideos2] = useState(true);
	const [name, setName] = useState(null);
	const [handel, setHandel] = useState(null);
	const [description, setDescription] = useState(null);
	const [contact, setContact] = useState('');
	const [photo, setPhoto] = useState('');
	const [banner, setBanner] = useState('');
	const [subscriber, setsubscriber] = useState(0);
	const [user_type, setUserType] = useState(0);
	const [refer_id, setReferId] = useState("");

	const [allChannels, setallChannels] = useState([]);
	const [loadingvideos1, setloadingvideos1] = useState(true);
	const [subs, setSubs] = useState({});
	const [open, setOpen] = useState(false);

	// new staet for modal data
	const [facebookLink, setFacebookLink] = useState(null);
	const [twitterLink, setTwitterLink] = useState(null);
	const [instagramLink, setInstagramLink] = useState(null);
	// const [contactLink, setContactLink] = useState(null);


	const navigate = useNavigate();

	// sidebar
	const [isSidebarCollapsed, setSidebarCollapsed] = useState(false);
	const [sidebarWidth, setSidebarWidth] = useState(280);
	const [sidebarOpen, setSidebarOpen] = useState(false);


	// Function to set subscribe data item by id
	const setItemById2 = (id, data) => {
		setSubs(prevState => ({
			...prevState,
			[id]: data
		}));
	};

	// Function to get item by id
	const getItemById2 = (id) => {
		return subs ? subs[id] : false;
	};


	const addSubscriber = async (chanelId) => {
		// console.log(chanelId);
		const response = await http.post('user/addSubscriber', { 'chanelId': chanelId });
		checksubscrive(chanelId);
	};


	const removeSubscriber = async (chanelId) => {
		const response = await http.post('user/removeSubscriber', { 'chanelId': chanelId });
		checksubscrive(chanelId);
	};


	const checksubscrive = async (chanelId) => {
		try {
			const response = await http.post('user/checkSubscriber', { 'chanelId': chanelId });
			//console.log('checksubs',response);
			if (response.data && response.data.status === 1) {


				setItemById2(chanelId, true);

			} else {
				setItemById2(chanelId, false);
			}
		} catch (error) {
			setItemById2(chanelId, false);
		}
	};




	const fetchChannels = async (refer_id) => {
		try {
			setloadingvideos1(true); // Set loading to true when starting the fetch

			const response = await http.post('user/idWiseReferChannel', { refer_id: refer_id });
			if (response.data && response.data.data) {
				setallChannels(response.data.data);
				// console.log(response.data.data);
			} else {
				console.error('Invalid data structure received from the API:', response.data);
			}
		} catch (error) {
			console.error('Error fetching video categories:', error);
		} finally {
			setloadingvideos1(false); // Set loading to false regardless of success or error
		}
	};

	useEffect(() => {
		if (userData && userData.refer_id && !apiRequestMade) {
			fetchChannels(userData.refer_id);
		}
	}, []); // Run the effect only once on component mount



	const viewVideo = async (videoId) => {
		await http.post('user/addView', { 'videoId': videoId });
	};

	const fetchVideo = async () => {
		try {
			const response = await http.post('user/userWiseVideo', { type: 1 });
			if (response.data && response.data.data) {
				//   console.log('allvideo', response.data.data);
				setallVideo(response.data.data);
			} else {
				console.error('Invalid data structure received from the API:', response.data);
			}
		} catch (error) {
			console.error('Error fetching video categories:', error);
		} finally {
			setloadingvideos(false);
		}
	};


	const fetchVideo2 = async () => {
		try {
			const response = await http.post('user/userWiseVideo', { type: 2 });
			if (response.data && response.data.data) {
				//   console.log('allvideo', response.data.data);
				setshortVideo(response.data.data);
			} else {
				console.error('Invalid data structure received from the API:', response.data);
			}
		} catch (error) {
			console.error('Error fetching video categories:', error);
		} finally {
			setloadingvideos2(false);
		}
	};

	const fetchChannel = async () => {
		try {
			const response = await http.post('user/myChanel');
			// console.log(response);
			if (response.data && response.data.status == 1) {


				setName(response.data.data.name);
				setHandel(response.data.data.url);
				setDescription(response.data.data.description);
				setContact(response.data.data.contact);
				setPhoto(response.data.data.photo);
				setBanner(response.data.data.banner);
				setsubscriber(response.data.data.subscriber);
				setFacebookLink(response.data.data.facebookLink);
				setTwitterLink(response.data.data.twitterLink);
				setInstagramLink(response.data.data.instagramLink);

				apiRequestMade = false;

			}
		} catch (error) {
			console.error('Error fetching Channel:', error);
		}
	};

	useEffect(() => {


		if (userData) {
			setUserType(userData.user_type);
		}
		if (userData.refer_id) {
			setReferId(userData.refer_id);
		}


		// console.log('api test: ',apiRequestMade);
		if (!apiRequestMade) {
			apiRequestMade = true; // Change the value to true
			fetchVideo();
			fetchVideo2();
			fetchChannel();
		}
	}, [apiRequestMade]);



	const handleClickOpen = (video) => {
		setOpen(true);
		setCurrentVideo(video);

	};

	const handleClose = () => {
		setOpen(false);
	};

	const handleEdit = (video) => {
		setEditVideo(video);
	}

	const handleDelete = async (video) => {

		try {
			const response = await http.post('user/deleteVideo', { videoId: video.videoId });
			if (response.data && response.data.status === 1) {
				const filteredData = allVideo.filter((vid) => vid.videoId !== video.videoId);
				setallVideo(filteredData);

				const filteredData2 = shortVideo.filter((vid) => vid.videoId !== video.videoId);
				setshortVideo(filteredData2);

				handleClose();
			} else {
				console.error('Invalid data structure received from the API:', response.data);
			}
		} catch (error) {
			console.error('Error fetching video categories:', error);
		}

	}

	const darkTheme = createTheme({
		palette: {
			divider: '#111',
			background: {
				paper: 'var(--header)', // Set your desired background color
			},
			text: {
				secondary: '#fff',
			},
			primary: {
				main: '#90caf9', // Adjust as needed
			},
			secondary: {
				main: '#FF6600', // Adjust as needed
			}
		},
	});


	const shareReferId = (refer_id) => {
		if (navigator.share) {
			navigator.share({
				title: "Share Mentor Refer Id",
				text: "Refer Id: " + refer_id,
				url: APP_URL + "?referId=" + refer_id
			}).then(() => {
				// console.log('Shared successfully');
			}).catch(error => {
				// console.error('Error sharing:', error);
			});
		}
	};

	const toggleSidebar = () => {
		setSidebarOpen(!sidebarOpen);
		setSidebarCollapsed(!isSidebarCollapsed);
		setSidebarWidth(isSidebarCollapsed ? 280 : 80); // Toggle sidebar width
	};

	return (
		<>
			<Header toggleSidebar={toggleSidebar} />
			<div className="col-xxl-2 col-xl-3 col-lg-3 display991" style={{ width: `${sidebarWidth}px` }}>
				{sidebarOpen ? (
					<SidebarIcon onClick={toggleSidebar} />
				) : (
					<Sidebar onClick={toggleSidebar} />
				)}
			</div>
			<div className="col-xxl-9 col-xl-9 col-lg-9" style={{ width: isSidebarCollapsed ? '1266px' : 'calc(100% - 280px)' }}>
				<div className="main__body__wrap left__right__space pt-0 pb-60">
					<div className='container'>
						<div className='row'>
							<div className='col-lg-12'>
								<div className='profile-box video-box-single-h '>
									<div className='my-3 mb-4' style={{ height: '180px', overflow: 'hidden' }}>
										{
											banner ? (
												<img src={banner} alt='Banner' width="100%" style={{ borderRadius: '10px', overflow: 'hidden' }} />
											) : (
												<img src={channelbanner} alt='Banner' width="100%" style={{ borderRadius: '10px', overflow: 'hidden' }} />
											)}
									</div>
									<div className='row g-3 align-items-center'>
										<div className='col-md-6 col-lg-3 col-xl-2'>
											<div className='profile-pic-box rounded-circle bg-white '>
												{
													photo ? (
														<img src={photo} className="rounded-circle  img-fluid" />
													) : (
														<img src={profilePic} className="rounded-circle  img-fluid" />
													)}
											</div>
										</div>
										<div className='col-lg-10 px-lg-3'>
											<div className='content-box shimmer-loading-list'>
												{
													name ?
														<Link to='/channel' className='text-dark'>
															{name}
														</Link>
														:
														<ShimmerLoading width="30%" height="20px" />
												}


												{(handel && allVideo) ?
													<>
														<div className='channel-name'>
															<Link to='/channel' className='text-theme'> @{handel} </Link>
															<span> <i className='bi bi-dot'></i> </span>
															<span className='text-secondary'>{subscriber} Subscriber</span>
															<span> <i className='bi bi-dot'></i> </span>
															<span className='text-secondary'>{allVideo && allVideo.length} videos</span>
															<p className='mt-1 mb-0'>
																<Link href='/channel' className='text-secondary'>
																	{description}
																</Link>
																<span style={{ cursor: 'pointer' }} data-bs-toggle="modal"
																	data-bs-target="#aboutModal"><b>..more</b>
																</span>
															</p>
															<p className='mb-0'><Link>{twitterLink || 'twitter.com/33'}</Link>
																<span style={{ cursor: 'pointer' }}
																	data-bs-toggle="modal"
																	data-bs-target="#aboutModal">
																	<b>  and 4 more links</b>
																</span>
															</p>
														</div>
														<div className='d-flex gap-2'>
															<Link to="/customization" className="cmn--btn rounded-5 px-3"><span>Customization</span></Link>
															{(user_type && user_type === "1") ? <button onClick={() => shareReferId(refer_id)} className="cmn--btn2 rounded-5 px-3">

																<span className='bi bi-share'> Share Refer Id: {refer_id}</span></button> : null}
															{/* <Link to="/manage-videos" className="btn btn-light  rounded-5 px-3"><small>Mannage Videos</small></Link> */}
														</div>
													</>

													:
													<>
														<ShimmerLoading width="60%" height="20px" />
														<ShimmerLoading width="90%" height="20px" />
														<ShimmerLoading width="20%" borderRadius="40px" height="30px" />
													</>
												}

											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						{/* top channer profile  row end */}
						<div className='border-top my-3'></div>
						<div className='row'>
							{/* <div className='col-12 theme-tabs'>
								<nav >
									<div className="nav nav-tabs mb-3" id="nav-tab" role="tablist">
										<button className="nav-link active" id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#nav-home" type="button" role="tab" aria-controls="nav-home" aria-selected="true">Videos</button>
										{(user_type && user_type === "1") ? <button className="nav-link" id="nav-refer-tab" data-bs-toggle="tab" data-bs-target="#nav-refer" type="button" role="tab" aria-controls="nav-refer" aria-selected="false">Refered Channels</button> : ""}
									</div>
								</nav>
								<div className="tab-content" id="nav-tabContent">
									<div className="tab-pane fade active show" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
										<div className='row g-3 row-cols-lg-3 row-cols-xl-3 row-cols-md-2 row-cols-sm-2'>

											{loadingvideos ? (
												<VideoListLoading />
											) : allVideo.length > 0 ? (
												allVideo.map((video, index) => (
													<div className='col'>
														<div className='video-box-single-v with-action wa-active' onClick={() => viewVideo(video.videoId)}>

															<Card className='shadow-none h-194-video'>

																<div className='video-box cursor-pointer'>
																	<Link to={VideoRoute(video.uniqId)}>
																		<div>
																			<CardMedia
																				component="video"
																				alt="Video Poster"
																				poster={video.thumbnail}
																				className='w-100'
																				muted
																			>
																				<source src={video.url} className='w-100' type="video/mp4" />
																			</CardMedia>
																		</div>

																	</Link>
																</div>
																<CardContent>
																	<div className='content-box'>
																		<div className='row align-items-start g-0'>
																			<div class="col-2">
																				<Link to={'/channel'}>
																					<div className='channel-thumbnail'>

																						{
																							video.channelIcon ? (
																								<img src={video.channelIcon} className="rounded-circle  img-fluid" />
																							) : (
																								<img src={profilePic} className="rounded-circle  img-fluid" />
																							)}
																					</div>
																				</Link>
																			</div>
																			<div className='col-9'>
																				<Typography variant="h5" component="div" className='v-tiltle'>
																					<Link to={VideoRoute(video.uniqId)}> {video.title}</Link>
																				</Typography>
																				<Typography variant="body2" color="text.secondary">
																					<Link to={'/channel'} className='channel-name mt-2'>{video.channelName}</Link>
																					<Link to={VideoRoute(video.uniqId)} className='views-time'>{formatViewsCount(video.view)} views <span>.</span> {<TimeAgo date={video.createdAt} />}</Link>
																				</Typography>
																			</div>
																			<div className='col-1'>
																				<div className="dropdown text-end">
																					<a href="#" className="d-block caret-none  text-decoration-none dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
																						<i class="bi bi-three-dots-vertical"></i>
																					</a>
																					<ul className="dropdown-menu">
																						<li><Link className="dropdown-item" href="URL:void(0)" onClick={() => handleShareLong(video.uniqId, video.title, video.description)} ><i className="me-3 bi bi-share"></i> Share</Link></li>
																						<li><Link className="dropdown-item" href="URL:void(0)" onClick={() => handel ? handleEdit(video) : ""} ><i class="bi bi-pencil-square me-3"></i> Edit </Link></li>
																						<li><Link className="dropdown-item" href="URL:void(0)" onClick={() => handel ? handleClickOpen(video) : ""} ><i class="bi bi-trash me-3"></i> Delete </Link></li>
																					</ul>
																				</div>
																			</div>
																		</div>
																	</div>
																</CardContent>
															</Card>
														</div>
													</div>
												))
											) : (
												<VideosNotFound />
											)}
										</div>
										<h2 className='h4 mt-3'><i class="bi bi-file-play text-theme"></i> Shorts</h2>
										<div className='row row-cols-lg-5 row-cols-md-3 row-cols-sm-2'>
											{loadingvideos2 ? (
												<ShortsListingLoading />
											) : shortVideo.length > 0 ? (
												shortVideo.map((video, index) => (
													<div className='col'>
														<div className='video-box-single-v with-action wa-active' onClick={() => viewVideo(video.videoId)}>
															<Card className='shorts-card shadow-none'>
																<div className='video-box cursor-pointer'>

																	<Link to={ShortsRoute(video.uniqId)}>
																		<div>
																			<CardMedia
																				component="video"
																				alt="Video Poster"
																				poster={video.thumbnail}
																				className='w-100'
																				muted
																			>
																				<source src={video.url} className='w-100' type="video/mp4" />
																			</CardMedia>
																		</div>
																	</Link>
																</div>
																<CardContent>
																	<div className='content-box'>
																		<div className='row align-items-start g-0'>

																			<div className='col-11'>
																				<Typography variant="h5" component="div" className='v-tiltle'>
																					<Link to={ShortsRoute(video.uniqId)}>{video.title} </Link>
																				</Typography>
																				<Typography variant="body2" color="text.secondary">
																					<Link href='/watch' className='views-time mt-2'>{formatViewsCount(video.view)} views </Link>
																				</Typography>
																			</div>
																			<div className='col-1'>
																				<div className="dropdown text-end">
																					<a href="#" className="d-block caret-none  text-decoration-none dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
																						<i class="bi bi-three-dots-vertical"></i>
																					</a>
																					<ul className="dropdown-menu">
																						<li><Link className="dropdown-item" href="URL:void(0)" onClick={() => handleShareShort(video.uniqId, video.title, video.description)} ><i className="me-3 bi bi-share"></i> Share</Link></li>
																						<li><Link className="dropdown-item" href="URL:void(0)" onClick={() => handel ? handleEdit(video) : ""} ><i class="bi bi-pencil-square me-3"></i> Edit </Link></li>
																						<li><Link className="dropdown-item" href="URL:void(0)" onClick={() => handel ? handleClickOpen(video) : ""} ><i class="bi bi-trash me-3"></i> Delete </Link></li>
																					</ul>
																				</div>
																			</div>
																		</div>
																	</div>
																</CardContent>
															</Card>
														</div>
													</div>
												))
											) : (
												<></>
											)}
										</div>
										{
											(allVideo || shortVideo) && (allVideo.length > 0 || shortVideo.length > 0) ? null : (
												<div className='col-lg-12 text-center'>
													<div className='border-top my-3'></div>
													<div className='box  '>
														<img src={ImgCreateVideo} className='img-fuid w-25' />
														<h2 className='h5 my-2'>Create content on any device</h2>
														<p className='w-50 m-auto '>Upload and record at home or on the go. Everything you make public will appear here.</p>
														<button type='button' className="btn btn-light rounded-4 px-5 mt-3" data-bs-toggle="modal" data-bs-target="#videoUpload" >Create </button>
													</div>
												</div>
											)
										}

									</div>
									{(user_type && user_type === "1") ? <div className="tab-pane fade" id="nav-refer" role="tabpanel" aria-labelledby="nav-refer-tab">

										<div className="main__body__wrap left__right__space pb-60 pt-20">
											<div className='row g-3'>

												{

													loadingvideos1 ? (
														<p>Loading Channels...</p>
													) : allChannels.length > 0 ? (
														allChannels.map((channel, index) => (

															<div className='col-12 col-lg-12 d-flex'>

																<div className='channel-item'>
																	<div className='row'>
																		<div className='col-4'>
																			<div className='channel-thumb'>

																				<div className='channel-thumbnail'>

																					{
																						channel.photo ? (
																							<img src={channel.photo} className="rounded-circle  img-fluid" />
																						) : (
																							<img src={profilePic} className="rounded-circle  img-fluid" />
																						)}
																				</div>
																			</div>
																		</div>
																		<div className='col-5'>
																			<div className='channel-data'>
																				<h2><a href='#' className='channel-name mb-0'>{channel.name ? channel.name : 'Channel name'}</a></h2>
																				<p><Link to={`/@${channel.url}`} className='views-time text-theme'><small className='text-theme'> @{channel.url} </small>
																					<small>{channel.subscriber ? channel.subscriber : '0'} subscribers</small>
																				</Link></p>
																				<p>
																					{channel.description}
																				</p>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														))
													) : (
														<p className='text-center'>No Refered channels available.</p>
													)}

											</div>
										</div>
									</div> : ""}
								</div>
							</div> */}
							{/* tabs st start  */}
							<ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
								<li className="nav-item" role="presentation">
									<button
										className="nav-link active py-2 px-3"
										id="pills-video-tab"
										data-bs-toggle="pill"
										data-bs-target="#pills-video"
										type="button"
										role="tab"
										aria-controls="pills-video"
										aria-selected="true"
									>
										Videos
									</button>
								</li>
								<li className="nav-item" role="presentation">
									<button
										className="nav-link py-2 px-3"
										id="pills-shorts-tab"
										data-bs-toggle="pill"
										data-bs-target="#pills-shorts"
										type="button"
										role="tab"
										aria-controls="pills-shorts"
										aria-selected="false"
									>
										Shorts
									</button>
								</li>
							</ul>
							<hr />
							<div className="tab-content" id="pills-tabContent">
								<div
									className="tab-pane fade show active"
									id="pills-video"
									role="tabpanel"
									aria-labelledby="pills-video-tab"
								>
									<div className='row g-3 row-cols-lg-3 row-cols-xl-3 row-cols-md-2 row-cols-sm-2'>
										{loadingvideos ? (
											<VideoListLoading />
										) : allVideo.length > 0 ? (
											allVideo.map((video, index) => (
												<div className='col'>
													<div className='video-box-single-v with-action' onClick={() => viewVideo(video.videoId)}>

														<Card className='shadow-none h-194-video'>

															<div className='video-box cursor-pointer'>
																<Link to={VideoRoute(video.uniqId)}>
																	{/*--<span className='duration-time'>11:11:11</span>--*/}
																	<div>
																		<CardMedia
																			component="video"
																			alt="Video Poster"
																			poster={video.thumbnail}
																			className='w-100'
																			muted
																		>
																			<source src={video.url} className='w-100' type="video/mp4" />
																		</CardMedia>
																	</div>

																</Link>
															</div>
															<CardContent>
																<div className='content-box'>
																	<div className='row align-items-start g-0'>
																		<div class="col-2">
																			<Link to={'/@' + video.handel}>
																				<div className='channel-thumbnail'>

																					{
																						video.channelIcon ? (
																							<img src={video.channelIcon} className="rounded-circle  img-fluid" />
																						) : (
																							<img src={profilePic} className="rounded-circle  img-fluid" />
																						)}
																				</div>
																			</Link>
																		</div>
																		<div className='col-9'>
																			<Typography variant="h5" component="div" className='v-tiltle'>
																				{/* <Link href='/watch'>{video.title}</Link> */}

																				<Link to={VideoRoute(video.uniqId)}> {video.title}</Link>
																			</Typography>
																			<Typography variant="body2" color="text.secondary">
																				<Link to={'/@' + video.handel} className='channel-name mt-2'>{video.channelName}</Link>
																				<Link to={VideoRoute(video.uniqId)} className='views-time'>{formatViewsCount(video.view)} views <span>.</span> {<TimeAgo date={video.createdAt} />}</Link>
																			</Typography>
																		</div>
																		<div className='col-1'>
																			<div className="dropdown text-end">
																				<a href="#" className="d-block caret-none  text-decoration-none dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
																					<i class="bi bi-three-dots-vertical"></i>
																				</a>
																				<ul className="dropdown-menu">
																					<li><Link className="dropdown-item" href="/login"><i class="bi bi-bar-chart-steps me-3"></i>Add to queue</Link></li>
																					<li><Link className="dropdown-item" href="/login"><i className="me-3 bi bi-clock-history"></i> Save to Watch Later</Link></li>
																					<li><Link className="dropdown-item" href="/login"><i class="bi bi-plus-square me-3"></i> Save to Playlist</Link></li>
																					<li><Link className="dropdown-item" href="/login"><i class="bi bi-download me-3"></i> Download</Link></li>
																					<li><Link className="dropdown-item" href="/login"><i className="me-3 bi bi-share"></i> Share</Link></li>
																					<li><hr className="dropdown-divider" /></li>
																					<li><Link className="dropdown-item" href="#"><i className="me-3 bi bi-ban"></i>  Not Interested </Link></li>
																					<li><Link className="dropdown-item" href="#"><i class="bi bi-dash-circle me-3"></i>  Don't Recomended Channel </Link></li>
																					<li><Link className="dropdown-item" href="#"><i className="me-3 bi bi-flag"></i>  Report </Link></li>
																				</ul>
																			</div>
																		</div>
																	</div>
																</div>
															</CardContent>
														</Card>
													</div>
												</div>
											))
										) : (
											<VideosNotFound />
										)}


									</div>
								</div>
								<div
									className="tab-pane fade"
									id="pills-shorts"
									role="tabpanel"
									aria-labelledby="pills-shorts-tab"
								>
									<div className='row row-cols-lg-5 row-cols-md-3 row-cols-sm-2'>
										{loadingvideos2 ? (
											<ShortsListingLoading />
										) : shortVideo.length > 0 ? (
											shortVideo.map((video, index) => (
												<div className='col'>
													<div className='video-box-single-v with-action' onClick={() => viewVideo(video.videoId)}>
														<Card className='shorts-card shadow-none'>
															<div className='video-box cursor-pointer'>

																<Link to={ShortsRoute(video.uniqId)}>
																	{/*--<span className='duration-time'>11:11:11</span>--*/}
																	<div>
																		<CardMedia
																			component="video"
																			alt="Video Poster"
																			poster={video.thumbnail}
																			className='w-100'
																			muted
																		// controls
																		>
																			<source src={video.url} className='w-100' type="video/mp4" />
																		</CardMedia>
																	</div>
																</Link>
															</div>
															<CardContent>
																<div className='content-box'>
																	<div className='row align-items-start g-0'>

																		<div className='col-11'>
																			<Typography variant="h5" component="div" className='v-tiltle'>
																				{/* <Link href='/watch'>{video.title}</Link> */}

																				<Link to={ShortsRoute(video.uniqId)}>{video.title} </Link>
																			</Typography>
																			<Typography variant="body2" color="text.secondary">
																				<Link href='/watch' className='views-time mt-2'>{formatViewsCount(video.view)} views </Link>
																			</Typography>
																		</div>
																		<div className='col-1'>
																			<div className="dropdown text-end">
																				<a href="#" className="d-block caret-none  text-decoration-none dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
																					<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-three-dots-vertical" viewBox="0 0 16 16">
																						<path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0" />
																					</svg>
																				</a>
																				<ul className="dropdown-menu">
																					<li><Link className="dropdown-item" href="/login"><i className="me-3 bi bi-share"></i> Share</Link></li>
																					<li><Link className="dropdown-item" href="/login"><i className="bi bi-send me-3"></i> Feedback</Link></li>

																				</ul>
																			</div>
																		</div>
																	</div>
																</div>
															</CardContent>
														</Card>
													</div>
												</div>
											))
										) : (
											<></>
										)}

									</div>
								</div>
							</div>

							{/* tabs st close */}
							{/* show when there are no video */}
						</div>
					</div>
				</div>
				{/* model for about channel */}
				<div
					className="modal fade"
					id="aboutModal"
					tabIndex={-1}
					aria-labelledby="aboutModalLabel"
					aria-hidden="true"
				>
					<div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
						<div className="modal-content">
							<div className="modal-header">
								<h4 className="modal-title" id="aboutModalLabel">About</h4>
								<button
									type="button"
									className="btn-close"
									data-bs-dismiss="modal"
									aria-label="Close"
								/>
							</div>
							<div className="modal-body">
								<p style={{ fontSize: '14px' }}>
									{description || 'We provide tutorials for SolidWorks, Autodesk Inventor, and Fusion 360. Contact us for more information!'}
								</p>
								<div className=''>
									<h4 className='mb-3'>Links</h4>
									<ul>
										<li className='d-flex mb-2'>
											<i className="bi bi-twitter-x me-3"></i>
											<h6>
												<a href={twitterLink} className='fs-12 text-info'>
													{twitterLink || 'Add Twitter Link'}
												</a>
											</h6>
										</li>
										<li className='d-flex mb-2'>
											<i className="bi bi-facebook me-3"></i>
											<h6>
												<a href={facebookLink} className='fs-12 text-info'>
													{facebookLink || 'Add Facebook Link'}
												</a>
											</h6>
										</li>
										<li className='d-flex mb-2'>
											<i className="bi bi-instagram me-3"></i>
											<h6>
												<a href={instagramLink} className='fs-12 text-info'>
													{instagramLink || 'Add Instagram Link'}
												</a>
											</h6>
										</li>
									</ul>
								</div>

								<div className=''>
									<h4 className='mb-3'>Channel Details</h4>
									<ul>
										<li className='d-flex mb-2'>
											<i className="bi bi-envelope me-3"></i>
											<h6>{contact}</h6>
										</li>
										<li className='d-flex mb-2'>
											<i className="bi bi-people me-3"></i>
											<h6>{subscriber} Subscribers</h6>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
				{/* modal about close  */}
			</div>

			{editVideo ? <EditVideo video={editVideo} setEditVideo={setEditVideo} /> : null}

			<ThemeProvider theme={darkTheme}>
				<Dialog open={open} onClose={handleClose}>
					<DialogTitle>Confirm Delete</DialogTitle>
					<DialogContent>
						<DialogContentText>
							Are you sure you want to delete this video? This action cannot be undone.
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={handleClose} color="primary">
							Cancel
						</Button>
						<Button onClick={() => handleDelete(currentVideo)} color="secondary">
							Delete
						</Button>
					</DialogActions>
				</Dialog>
			</ThemeProvider>
		</>
	)
}

export default Channel