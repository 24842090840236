import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import { getToken } from "../../auth/AuthUser";
import profile from '../../assets/images/profile-pic.jpg';
import { authHttps } from "../../auth/AuthUser";
import { Switch, FormControlLabel } from '@mui/material';
import { Brightness4, Brightness7, SupervisedUserCircle, VideoCall } from '@mui/icons-material'; // Import the moon and sun icons
function Topbar() {
    const http = authHttps();
    let token = getToken();
    const [chanenl, setChanneel] = useState(false);
    const [profiledata, setProfile] = useState([]);




    const [theme, setTheme] = useState(localStorage.getItem('theme') || 'dark');

    useEffect(() => {
        const savedTheme = localStorage.getItem('theme');
        if (savedTheme) {
            document.body.className = theme;
            setTheme(savedTheme);
        } else {

            localStorage.setItem('theme', theme);
        }
    }, []);


    const toggleTheme = () => {
        const newTheme = theme === 'light' ? 'dark' : 'light';
        localStorage.setItem('theme', newTheme);
        if (theme === 'light') {
            document.body.classList.add('light');
            document.body.classList.remove('dark');
        } else {
            document.body.classList.add('dark');
            document.body.classList.remove('light');
        }
        document.body.style.setProperty('--body-color', newTheme === 'light' ? 'hsl(0, 0%, 98%)' : '#060C1F');
        document.body.style.setProperty('--header', newTheme === 'light' ? '#eaeaea' : '#202A39');
        document.body.style.setProperty('--subheader', newTheme === 'light' ? '#dadada' : '#161F2C');
        document.body.style.setProperty('--bg-theme-1', newTheme === 'light' ? 'var(--active-3)' : 'var(--active-color)');
        document.body.style.setProperty('--theme-one', newTheme === 'light' ? 'var(--theme-two)' : '#88B6FF');
        document.body.style.setProperty('--slidebox', newTheme === 'light' ? '#ebebeb' : '#141C33');
        document.body.style.setProperty('--white', newTheme === 'light' ? '#000' : '#fff');
        document.body.style.setProperty('--black', newTheme === 'light' ? '#fff' : '#000');
        document.body.style.setProperty('--white-color', newTheme === 'light' ? '#000' : '#fff');
        document.body.style.setProperty('--black-color', newTheme === 'light' ? '#000' : '#000');
        document.body.style.setProperty('--bs-modal-color', newTheme === 'light' ? '#000' : '#fff');
        document.body.style.setProperty('--border-theme2', newTheme === 'light' ? '#797979' : '#797979');
        document.body.style.setProperty('--border-theme3', newTheme === 'light' ? '#555' : '#555');
        document.body.style.setProperty('--border-theme4', newTheme === 'light' ? '#333' : '#333');
        document.body.style.setProperty('--color-444', newTheme === 'light' ? '#444' : '#444');
        document.body.style.setProperty('--bg-secondary', newTheme === 'light' ? 'var(--bg-theme-1)' : 'var(--bg-theme-1)');
        document.body.style.setProperty('--color-text', newTheme === 'light' ? '#333' : '#a9a9a9');
        document.body.style.setProperty('--bs-light-rgb', newTheme === 'light' ? '0,0,0' : '248,249,250');
        document.body.style.setProperty('--bs-white-rgb', newTheme === 'light' ? '0,0,0' : '255,255,255');
        document.body.style.setProperty('--bs-nav-link-color', newTheme === 'light' ? '#000' : 'var(--icon-color)');
        setTheme(newTheme);
    };

    const fetchChannel = async () => {
        try {
            const response = await http.post('user/myChanel');
            if (response.data && response.data.status == 1) {

                setChanneel(true);

            }
        } catch (error) {
            console.error('Error fetching Channel:', error);
        }
    };


    useEffect(() => {
        fetchChannel();
    }, []);


    const fetchUser = async () => {
        try {
            const response = await http.post('user/getUser');
            // console.log('profile', response.data.data);
            if (response.data && response.data.status == 1) {

                setProfile(response.data.data);



            }
        } catch (error) {
            setProfile([]);
        }
    };


    useEffect(() => {
        fetchUser();
    }, []);

    const [fl, setFirstLetter] = useState(null);


    useEffect(() => {
        const letters = profiledata.full_name ? profiledata.full_name.split('') : [];
        const firstLetter = letters.length > 0 ? letters[0] : '';

        setFirstLetter(firstLetter);

    }, [profiledata]);



    return (

        <>
            <div className='col-lg-2 col-8 order-lg-2 d-flex align-items-center justify-content-end'>
                {/*<div className='d-flex align-items-center justify-content-end'>
                    <FormControlLabel
                        control={<Switch checked={theme === 'dark'} onChange={toggleTheme} />}
                        label={theme === 'dark' ? <i className="bi secondary-color"><Brightness4 /></i> : <i className="bi secondary-color"><Brightness7 /></i>} // Render moon or sun icon based on theme
                    />
    </div>*/}
                {
                    token ?
                        <div className='d-flex align-items-center justify-content-end'>
                            <div className="me-lg-4 me-2">
                                <a href="#" className="navbtn-ico  caret-none dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false" title='Create'>
                                   <i class="bi bi-plus-circle fs-4 fw-700"></i>
                                </a>
                                <ul className="dropdown-menu">

                                    {chanenl == true ? (
                                        <li><a className="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#videoUpload"> Upload Video</a></li>
                                    ) : (
                                        <Link to="./create-channel" className="dropdown-item">Create Chanel</Link>
                                    )}
                                    {/* <li><a className="dropdown-item" href="#">Go Live</a></li> */}
                                </ul>
                            </div>
                            {/* <div className="dropdown text-end me-4 ">
                                <Link href="#" className="d-block caret-none  text-decoration-none dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                    <i className='bi bi-bell secondary-color fs-4'></i>
                                </Link>
                                <ul className="dropdown-menu p-3">
                                    <li>
                                        <Link to='/'>
                                            <div className='mb-2' style={{ minWidth: '320px' }}>
                                                <div className='row'>
                                                    <div className='col-2 text-center'>
                                                        <img src={profile} alt="mdo" width="30" height="30" className="rounded-circle mr-1 mt-2" />
                                                    </div>
                                                    <div className='col-10'>
                                                        <p className='mb-0 fs-6 mt-0'>
                                                            Lorem Ipsum Lorem Ipsum
                                                        </p>
                                                        <small className='me-2'>
                                                            <span>Date:</span>
                                                            22-04-2022
                                                        </small>
                                                        <small>
                                                            <span>Time:</span>
                                                            2 hour ago
                                                        </small>
                                                    </div>
                                                </div>
                                            </div>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to='/'>
                                            <div className='mb-2' style={{ minWidth: '320px' }}>
                                                <div className='row'>
                                                    <div className='col-2 text-center'>
                                                        <img src={profile} alt="mdo" width="30" height="30" className="rounded-circle mr-1 mt-2" />
                                                    </div>
                                                    <div className='col-10'>
                                                        <p className='mb-0 fs-6 mt-0'>
                                                            Lorem Ipsum Lorem Ipsum
                                                        </p>
                                                        <small className='me-2'>
                                                            <span>Date:</span>
                                                            22-04-2022
                                                        </small>
                                                        <small>
                                                            <span>Time:</span>
                                                            2 hour ago
                                                        </small>
                                                    </div>
                                                </div>
                                            </div>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to='/'>
                                            <div className='mb-2' style={{ minWidth: '320px' }}>
                                                <div className='row'>
                                                    <div className='col-2 text-center'>
                                                        <img src={profile} alt="mdo" width="30" height="30" className="rounded-circle mr-1 mt-2" />
                                                    </div>
                                                    <div className='col-10'>
                                                        <p className='mb-0 fs-6 mt-0'>
                                                            Lorem Ipsum Lorem Ipsum
                                                        </p>
                                                        <small className='me-2'>
                                                            <span>Date:</span>
                                                            22-04-2022
                                                        </small>
                                                        <small>
                                                            <span>Time:</span>
                                                            2 hour ago
                                                        </small>
                                                    </div>
                                                </div>
                                            </div>
                                        </Link>
                                    </li>
                                </ul>
                            </div> */}

                            <div className="dropdown text-end">
                                <a href="#" className="d-block caret-none  text-decoration-none dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">

                                    {profiledata.photo && profiledata.photo !== "N/A" ? (
                                        <img src={profiledata.photo} alt="mdo" width="45" height="45" className="rounded-circle" />
                                    ) : (

                                        <div
                                            className='profile-ico'
                                        >
                                            {fl ? fl : ''}

                                        </div>



                                    )}
                                </a>
                                <ul className="dropdown-menu ">

                                    <li><Link className="dropdown-item" to="/profile">Profile</Link></li>
                                    <li><Link className="dropdown-item" to="/support">Support</Link></li>
                                    <li><hr className="dropdown-divider" /></li>
                                    <Link to="/signout"><a className="dropdown-item" href="#">Sign out</a></Link>
                                </ul>
                            </div>
                        </div>

                        :

                        <div className="mneu-btn-grp">
                            {/* <Link to="/login" className='text-decoration-none secondary-color '>
                                <div className="text-end me-lg-4 me-2">
                                    <div className="d-block caret-none" >
                                        <img src={profile} alt="mdo" width="32" height="32" className="rounded-circle" /> Sign in
                                    </div>
                                </div>
                            </Link> */}

                            {/* <div className="dropdown text-end me-4 ">
                                <Link href="#" className="d-block caret-none  text-decoration-none dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                    <i className='bi bi-bell secondary-color fs-4'></i>
                                </Link>
                                <ul className="dropdown-menu p-3">
                                    <li>
                                        <Link to='/'>
                                            <div className='mb-2' style={{ minWidth: '320px' }}>
                                                <div className='row'>
                                                    <div className='col-2 text-center'>
                                                        <img src={profile} alt="mdo" width="30" height="30" className="rounded-circle mr-1 mt-2" />
                                                    </div>
                                                    <div className='col-10'>
                                                        <p className='mb-0 fs-6 mt-0'>
                                                            Lorem Ipsum Lorem Ipsum
                                                        </p>
                                                        <small className='me-2'>
                                                            <span>Date:</span>
                                                            22-04-2022
                                                        </small>
                                                        <small>
                                                            <span>Time:</span>
                                                            2 hour ago
                                                        </small>
                                                    </div>
                                                </div>
                                            </div>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to='/'>
                                            <div className='mb-2' style={{ minWidth: '320px' }}>
                                                <div className='row'>
                                                    <div className='col-2 text-center'>
                                                        <img src={profile} alt="mdo" width="30" height="30" className="rounded-circle mr-1 mt-2" />
                                                    </div>
                                                    <div className='col-10'>
                                                        <p className='mb-0 fs-6 mt-0'>
                                                            Lorem Ipsum Lorem Ipsum
                                                        </p>
                                                        <small className='me-2'>
                                                            <span>Date:</span>
                                                            22-04-2022
                                                        </small>
                                                        <small>
                                                            <span>Time:</span>
                                                            2 hour ago
                                                        </small>
                                                    </div>
                                                </div>
                                            </div>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to='/'>
                                            <div className='mb-2' style={{ minWidth: '320px' }}>
                                                <div className='row'>
                                                    <div className='col-2 text-center'>
                                                        <img src={profile} alt="mdo" width="30" height="30" className="rounded-circle mr-1 mt-2" />
                                                    </div>
                                                    <div className='col-10'>
                                                        <p className='mb-0 fs-6 mt-0'>
                                                            Lorem Ipsum Lorem Ipsum
                                                        </p>
                                                        <small className='me-2'>
                                                            <span>Date:</span>
                                                            22-04-2022
                                                        </small>
                                                        <small>
                                                            <span>Time:</span>
                                                            2 hour ago
                                                        </small>
                                                    </div>
                                                </div>
                                            </div>
                                        </Link>
                                    </li>
                                </ul>
                            </div> */}

                            <Link
                                className="cmn--btn"
                                style={{padding:'10px 10px'}}
                                href="URL:void(0)"
                                data-bs-toggle="modal"
                                data-bs-target="#signInPin"
                            >
                                <span>Sign In / Sign Up</span>
                            </Link>
                            {/* <Link
                                href="URL:void(0)"
                                className="cmn--btn2"
                                data-bs-toggle="modal"
                                data-bs-target="#signUpPin"
                            >
                                <span className="rela">Sign Up</span>
                            </Link> */}
                        </div>
                }
            </div>
        </>
    )
}

export default Topbar