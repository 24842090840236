import React, { useState, useEffect, useRef } from 'react';

import profilePic from "../assets/images/profile-pic.jpg";
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Helmet } from 'react-helmet';
import ReadMoreVideoDesc from '../Components/ReadMoreVideoDesc';
import { Link, useParams, useNavigate, useLocation } from 'react-router-dom'
import { getToken } from "../auth/AuthUser";
import { authHttps } from "../auth/AuthUser";
import { formatDistanceToNow } from 'date-fns';
import VideoNotAvailable from '../Components/ErrorPages/VideoNotAvailable';
import WatchSingleLoading from '../Components/LoadingPages/WatchSingleLoading';
import RelatedVideoLoading from '../Components/LoadingPages/RelatedVideoLoading';
import { handleShareViaAPI, VideoRoute } from '../utils/routeUtils';
import Header from '../Components/Includes/Header';

function WatchSinglePage() {
    const { uniqId } = useParams();
    const navigate = useNavigate();

    const location = useLocation();
    const currentUrl = location.pathname; // This will give you the current URL


    const http = authHttps();
    let token = getToken();

    const [adUrl, setAdUrl] = useState(null); // Ad video URL from Bunny.net
    const [currentAdId, setCurrentAdId] = useState(null);
    const [adDurationInMilliseconds, setAdDurationInMilliseconds] = useState(null);
    
    const [adPlayed, setAdPlayed] = useState(false); // Has the ad been played
    const [showSkipButton, setShowSkipButton] = useState(false); // Show skip button after 5 seconds
    const adIframeRef = useRef(null); // Reference to the ad iframe
    const mainVideoRef = useRef(null); // Reference to the main video iframe


    const [videoData, setVideoData] = useState(null);
    const [handelData, setHandelData] = useState(null);
    const [loadingVideo, setloadingvideos] = useState(true);

    const [relatedVideos, setRelatedVideos] = useState([]);
    const [loadingvideoss, setloadingvideoss] = useState(true);

    const [name, setName] = useState('Channel Name');
    const [description, setDescription] = useState('');
    const [photo, setPhoto] = useState('');
    const [subscriber, setSubscriber] = useState(0);
    const [like, setLike] = useState('');
    const [dlike, setDlike] = useState('');
    const [chanelId, setChanelId] = useState('');
    const [videoId, setVideoId] = useState('');
    const [userId, setUserId] = useState('');
    
    const [catid, setCatId] = useState('');
    const [subs, setSubs] = useState(false);
    const [watchs, setCheck] = useState(false);
    
    const [lkis, setSubs1] = useState({
        like: 0,
        dlike: 0,
        user: 0
    });
    const [comments, setComments] = useState('');
    const [allComment, setallComment] = useState([]);
    const [loadingcomment, setloadingcomment] = useState(false);


    // const handleShare = (uniqId, title, description) => {
    //     if (navigator.share) {
    //         navigator.share({
    //             title: title,
    //             text: description,
    //             url: "https://kbtube.com/watch/" + uniqId
    //         }).then(() => {
    //             console.log('Shared successfully');
    //         }).catch(error => {
    //             console.error('Error sharing:', error);
    //         });
    //     } else {
    //         console.log('Web Share API not supported');
    //     }
    // };

    useEffect(() => {
        // If the ad duration is available, set a timeout to simulate the ad completion
        if (adDurationInMilliseconds) {
            console.log('Ad duration:', adDurationInMilliseconds);
    
            // Set a timeout to trigger when the ad "ends"
            const adTimeout = setTimeout(() => {
                handleAdEnd(); // Trigger the main video after the ad ends
            }, adDurationInMilliseconds); // Wait for the ad duration
    
            // Cleanup the timeout if component unmounts or ad data changes
            return () => clearTimeout(adTimeout);
        }
    }, [adDurationInMilliseconds]);

    const handleAdEnd = async () => {
        setAdPlayed(true); // Mark the ad as played
    
        // Log ad interaction (fully watched)
        try {
            const response = await http.post('/user/ad-interaction', {
                videoId: videoId,   // Pass the video ID
                adId: currentAdId,  // Pass the current ad ID
                userId: userId,     // Pass the user ID
                isSkipped: false    // Ad was fully watched
            });
        } catch (error) {
            console.error('Error logging ad interaction:', error);
        }
    
        // Reload the iframe with autoplay enabled
        if (mainVideoRef.current) {
            const currentSrc = videoData.url; // Main video URL
            mainVideoRef.current.src = ''; // Clear the src first to force reload
            mainVideoRef.current.src = `${currentSrc}?autoplay=1`; // Reload the main video with autoplay
        }
    };
    
    // Skip the ad
    const handleSkipAd = async () => {
        setAdPlayed(true); // Mark ad as played
    
        // Log ad interaction (skipped)
        try {
            const response = await http.post('/user/ad-interaction', {
                videoId: videoId,   // Pass the video ID
                adId: currentAdId,  // Pass the current ad ID
                userId: userId,     // Pass the user ID
                isSkipped: true     // Ad was skipped
            });
    
            // Handle response if needed, e.g., check response.status or response.data
        } catch (error) {
            console.error('Error logging ad interaction:', error);
        }
    
        // Reload the main video with autoplay enabled
        if (mainVideoRef.current) {
            mainVideoRef.current.src += '?autoplay=true';
        }
    };

    // Show skip button after 5 seconds
    useEffect(() => {
        if (adUrl) {
            const timer = setTimeout(() => setShowSkipButton(true), 5000); // Show skip after 5 seconds
            return () => clearTimeout(timer); // Cleanup the timer on unmount
        }
    }, [adUrl]);

    const fetchVideo = async (uniqId) => {
        setloadingvideos(true);
        try {
            const response = await http.post('user/UniqIdWiseVideo', { 'uniqId': uniqId });
            const getData = await http.post('user/allVideoAds', { });
    
            if (response.data && response.data.data) {
                const Video = response.data.data;
                setVideoData(Video);
                console.log('response',response.data);
                
                // Ensure adsData exists and is an array before accessing its properties
                const adsData = response.data.adsData;
                if (adsData && Array.isArray(adsData) && adsData.length > 0) {
                    const adUrl = adsData[0].ads_video || null;
                    const adId = adsData[0]._id || null;
                    const adDuration = adsData[0].duration * 1000 || null;
                    
                    setAdUrl(adUrl);
                    setCurrentAdId(adId);
                    setAdDurationInMilliseconds(adDuration);
                } else {
                    console.warn('No adsData or adsData is empty');
                    setAdUrl(null);
                    setCurrentAdId(null);
                    setAdDurationInMilliseconds(null);
                }
    
                setHandelData(response.data.handel);
                setName(Video.name);
                setChanelId(Video.chanelId);
                setVideoId(Video.videoId);
                setUserId(Video.userId);
                setCatId(Video.playlistCategory);
                setDescription(Video.description);
                setPhoto(Video.photo);
                setSubscriber(parseInt(Video.subscriber));
                setLike(Video.like);
                setDlike(Video.dlike);
    
                /*********** after confirming and loading video start getting other data ***********/
                fetchAllVideo(Video.playlistCategory, videoId);
                checksubscrive(Video.videoId);
                checkWatch(Video.videoId);
                checklike(Video.videoId);
                fetchAllComments(Video.videoId);
                /*********** after confirming and loading video start getting other data ***********/
    
            } else {
                console.error('Invalid data structure received from the API:', response.data);
            }
        } catch (error) {
            console.error('Error fetching video categories:', error);
        } finally {
            setloadingvideos(false);
        }
    };
    
    useEffect(() => {
        fetchVideo(uniqId);
    }, [uniqId]);
    


    const viewVideo = async (videoId) => {
        await http.post('user/addView', { 'videoId': videoId });
    };



    const fetchAllVideo = async (catid, videoId) => {

        setloadingvideoss(true);
        try {
            // const catid = localStorage.getItem('catid');
            const response = await http.post('user/categoryWiseVideo', { 'categoryId': catid });
            if (response.data && response.data.data) {
                const relatedVideos = response.data.data.filter(item => item.videoId !== videoId);
                setRelatedVideos(relatedVideos);
            } else {
                console.error('Invalid data structure received from the API:', response.data);
            }
        } catch (error) {
            console.error('Error fetching video categories:', error);
        } finally {
            setloadingvideoss(false);
        }
    };


    const TimeAgo = ({ date }) => {
        // Assuming `date` is a string in the format 'YYYY-MM-DDTHH:mm:ss.sssZ'
        const parsedDate = new Date(date);

        const timeAgo = formatDistanceToNow(parsedDate, { addSuffix: true });

        return <span>{timeAgo}</span>;
    };



    const formatViewsCount = (count) => {
        if (count < 1000) {
            return count.toString();
        } else if (count < 1000000) {
            return `${(count / 1000).toFixed(1)}K`;
        } else {
            return `${(count / 1000000).toFixed(1)}M`;
        }
    };




    const addSubscriber = async (chanelId, videoId) => {
        const response = await http.post('user/addSubscriber', { 'chanelId': chanelId });

        if (response.data && response.data.status === 1) {
            setSubscriber(parseInt(subscriber) + 1);
            checksubscrive(videoId);
        }
    };

    
    const addWatchLater = async (chanelId, videoId) => {

        const response = await http.post('user/WatchMaster', { 'chanelId': chanelId,videoId: videoId,type:2});

        if (response.data && response.data.status === 1) {
            checkWatch(videoId);
        }
    };



    const removeSubscriber = async (chanelId, videoId) => {
        const response = await http.post('user/removeSubscriber', { 'chanelId': chanelId });
        if (response.data && response.data.status === 1) {
            setSubscriber(parseInt(subscriber) - 1);
            checksubscrive(videoId);
        }
    };


    const likeMaster = async (chanelId, videoId, type) => {

        // const videoId = localStorage.getItem('videoId');
        const response = await http.post('user/likeMaster', { 'chanelId': chanelId, 'videoId': videoId, 'type': type });
        if (response.data && response.data.status === 1) {

            if (type === 1) {
                setLike(like + 1);

                setSubs1({ like: 1, dlike: 0, user: 0 });

                if (response.data.action === 2) {
                    setDlike(dlike - 1);
                }

                if (response.data.action === 3) {
                    setLike(like - 1);

                    setSubs1({ like: 0, dlike: 0, user: 0 });
                }
            } else {
                setDlike(dlike + 1);
                setSubs1({ like: 0, dlike: 1, user: 0 });

                if (response.data.action === 2) {
                    setLike(like - 1);
                }
                if (response.data.action === 3) {
                    setDlike(dlike - 1);

                    setSubs1({ like: 0, dlike: 0, user: 0 });
                }
            }


        }
        //checklike();
    };

    const checksubscrive = async (videoId) => {
        try {
            // const videoId = localStorage.getItem('videoId');
            const response = await http.post('user/checkSubscriber', { 'videoId': videoId });
            //console.log('checksubs',response);
            if (response.data && response.data.status === 1) {
                setSubs(true);

                //alert('done');
            } else {
                setSubs(false);
            }
        } catch (error) {
            setSubs(false);
        }
    };



    const checkWatch = async (videoId) => {
        try {
            // const videoId = localStorage.getItem('videoId');
            const response = await http.post('user/checkWatch', { 'videoId': videoId });
            //console.log('checksubs',response);
            if (response.data && response.data.status === 1) {
                setCheck(true);

                //alert('done');
            } else {
                setCheck(false);
            }
        } catch (error) {
            setCheck(false);
        }
    };




    const checklike = async (videoId) => {
        try {
            // const videoId = localStorage.getItem('videoId');
            const response = await http.post('user/checkLike', { 'videoId': videoId });

            setSubs1(response.data.data);


        } catch (error) {
            setSubs1({
                like: 0,
                dlike: 0,
                user: 0
            });
        }
    };


    /*************************************** Comment System Functions ***************************************/
    const addComment = async (videoId) => {
        // const videoId = localStorage.getItem('videoId');
        const response = await http.post('user/addComment', { 'videoId': videoId, 'comments': comments, });
        fetchAllComments(videoId);
        setComments('');
    };

    const fetchAllComments = async (videoId) => {
        try {
            // const videoId = parseInt(localStorage.getItem('videoId'));
            const response = await http.post('user/videoIdWiseComment', { 'videoId': videoId, });

            // console.log('comm', response);

            if (response.data && response.data.data) {
                setallComment(response.data.data);
            } else {
                console.error('Invalid data structure received from the API:');
                // console.error('Invalid data structure received from the API:', response.data);
            }
        } catch (error) {
            console.error('Error fetching video categories:');
            // console.error('Error fetching video categories:', error);
        } finally {
            setloadingcomment(false);
        }
    };


    const [user, setUser] = useState(null);

    const fetchChannel = async () => {
        try {
            const response = await http.post('user/getUser');



            if (response.data && response.data.status === 1) {



                setUser(response.data.data);



            }
        } catch (error) {
            console.error('Error get User:', error);
        }
    };





    useEffect(() => {
        fetchChannel();
    }, []);


    return (
        <>
            <Header/>
            {loadingVideo ? (
                <WatchSingleLoading />
            ) : videoData && videoData.url ? (

                <div className='container-fluid mt-3 px-lg-4'>
                    {/* <Helmet>
                        <title> {videoData.title} | KB TuBE</title>

                        <link rel="canonical" href={currentUrl} />
                        <meta name="og:title" content={videoData && videoData.tags ? videoData.tags : videoData.title + ", KB TuBE"} />
                        <meta name="og:description" content={videoData && videoData.description} />
                        <meta property="og:image" content={videoData && videoData.thumbnail} />
                        <meta name="description" content={videoData && videoData.description} />
                        <meta name="keyword" content={videoData && videoData.tags ? videoData.tags : videoData.title + ", KB TuBE"} />
                    </Helmet> */}
                    <Helmet>
                        <title>{videoData.title} | KB TuBE</title>
                        <meta name="description" content={videoData.title} />
                        <meta property="og:title" content={videoData.title} />
                        <meta property="og:description" content={videoData.title} />
                        <meta property="og:type" content="video" />
                        <meta property="og:video" content={videoData && videoData.url ? videoData.url + '?autoplay=true' : ''} />
                        <meta property="og:video:secure_url" content={videoData && videoData.url ? videoData.url + '?autoplay=true' : ''} />
                        <meta property="og:video:type" content="video/mp4" />
                        <meta property="og:image" content={videoData && videoData.thumbnail} />
                        <meta property="og:image:secure_url" content={videoData && videoData.thumbnail} />
                        <meta property="og:image:type" content="image/jpeg/png/jpg/gif" />
                        <meta property="og:image:width" content="1200" />
                        <meta property="og:image:height" content="630" />
                    </Helmet>
                    <div className='row'>
                        <div className='col-md-8'>

                            <>
                                <div className='watch-video-comment-box'>
                                    <div className='video-box-single-v'>
                                        <Card className='shadow-none  overflow-visible'>                                            
                                            <div className='video-box'>
                                                {/* <iframe
                                                    className='single-video'
                                                    src={videoData && videoData.url ? videoData.url + '?autoplay=true' : ''}
                                                    allow="autoplay; fullscreen; picture-in-picture"
                                                ></iframe> */}

                                                {adUrl && !adPlayed && (
                                                    <div>
                                                        <iframe
                                                            ref={adIframeRef}
                                                            className="single-video"
                                                            src={`${adUrl}?autoplay=1`} // Bunny.net ad URL with autoplay enabled
                                                            allow="autoplay; fullscreen;"  
                                                            onEnded={handleAdEnd} // When ad ends, play the main video
                                                        ></iframe>

                                                        {showSkipButton && (
                                                            <button onClick={handleSkipAd} className="skip-button rounded-50">
                                                                Skip | Ad
                                                            </button>
                                                        )}
                                                    </div>
                                                )}

                                                {/* Main video iframe - only shown after ad has been played or skipped */}
                                                {(adPlayed || !adUrl) && videoData && (
                                                    <iframe
                                                        ref={mainVideoRef}
                                                        className="single-video"
                                                        src={videoData.url} // Main video URL from Bunny.net
                                                        allow="autoplay; fullscreen; picture-in-picture"                                          
                                                    ></iframe>
                                                )}
                                            </div>
                                            <CardContent >
                                                <div className='content-box'>
                                                    <div className='row'>
                                                        <div className='col-12'>
                                                            <h1 className='v-tiltle'>
                                                                {videoData.title}
                                                            </h1>
                                                        </div>
                                                        <div className='col-lg-12'>
                                                            <Typography variant="body2" color="text.secondary" >
                                                                <div className='row  g-1 align-items-center justify-content-between mt-1'>

                                                                    <div className='col-xs-12  col-lg-3 d-flex'>

                                                                        <div className='channel-thumbnail'>

                                                                            <Link to={handelData ? '/@' + handelData : '#'}>
                                                                                {
                                                                                    videoData.photo ? (
                                                                                        <img src={videoData.photo} className="rounded-circle  img-fluid" />
                                                                                    ) : (
                                                                                        <img src={profilePic} className="rounded-circle  img-fluid" />
                                                                                    )}
                                                                            </Link>
                                                                        </div>

                                                                        <div className='px-2'>
                                                                            <Link to={handelData ? '/@' + handelData : '#'} className='channel-name mb-0'>{name ? name : 'Channel name'}</Link>
                                                                            <Link to={handelData ? '/@' + handelData : '#'} className='views-time'><small> {subscriber ? subscriber : '0'} subscribers</small></Link>

                                                                        </div>
                                                                    </div>

                                                                    <div className='col-xs-12 col-lg-3 d-flex align-items-center justify-content-end'>
                                                                        {token ? (
                                                                            subs ? (
                                                                                <div className="dropdown">
                                                                                    <Link href="#" className="d-block caret-none  btn btn-secondary text-light w-100 rounded-5 px-3 text-decoration-none dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                                                                        <i class="bi bi-bell"></i> Subscribed <i class="bi bi-chevron-down"></i>
                                                                                    </Link>
                                                                                    <ul className="dropdown-menu">
                                                                                        <li><Link className="dropdown-item" href="/login"><i class="bi bi-bell-fill me-3"></i>All</Link></li>
                                                                                        {/* <li><Link className="dropdown-item" href="/login"><i className="me-3 bi bi-bell"></i> Personalized</Link></li>
                                                                                <li><Link className="dropdown-item" href="/login"><i class="bi bi-bell-slash me-3"></i> None</Link></li> */}
                                                                                        <li><Link className="dropdown-item" href="#" onClick={() => removeSubscriber(chanelId, videoId)}><i class="bi bi-person-dash-fill me-3"></i> Unsubscribe</Link></li>
                                                                                    </ul>
                                                                                </div>

                                                                            ) : (
                                                                                <button type='button' onClick={() => addSubscriber(chanelId, videoId)} className='btn btn-secondary text-light rounded-5 px-4 me-1'> <small>Subscribe</small></button>
                                                                            )

                                                                        ) : (
                                                                            <Link to="/login" className='btn btn-secondary text-light rounded-5 px-4 me-1'> <small>Subscribe</small></Link>
                                                                        )
                                                                        }


                                                                         {token ? (
                                                                            watchs ? (
                                                                                <button type='button' onClick={() => addWatchLater(chanelId, videoId)} className='btn btn-secondary text-light rounded-5 px-4'> <small>Saved</small></button>
                                                                            ) : (
                                                                                <button type='button' onClick={() => addWatchLater(chanelId, videoId)} className='btn btn-secondary text-light rounded-5 px-4'> <small>Save</small></button>
                                                                            )

                                                                        ) : (
                                                                            <Link to="/login" className='btn btn-secondary text-light rounded-5 px-4 ms-1 me-1'> <small>Save</small></Link>
                                                                        )
                                                                        }

                                                                        

                                                                    </div>

                                                                    {token ? (
                                                                        <>
                                                                            <div className='col-6 col-lg-3  d-flex align-items-center justify-content-start justify-content-lg-end'>
                                                                                <div className='btn-secondary rounded-5  d-inline-flex align-items-center justify-content-around'>
                                                                                    <button onClick={() => likeMaster(chanelId, videoId, 1)} type='button' className='btn btn-secondary text-light rounded-5 border-0'>
                                                                                        <small>
                                                                                            <i className={`${lkis.like === 1 ? 'bi bi-hand-thumbs-up-fill' : 'bi bi-hand-thumbs-up'}`} ></i> {like ? like : '0'}
                                                                                        </small>
                                                                                    </button>
                                                                                    <span>|</span>
                                                                                    <button onClick={() => likeMaster(chanelId, videoId, 2)} type='button' className='btn btn-secondary btn-secondary text-light rounded-5 border-0'>
                                                                                        <small>
                                                                                            <i className={`${lkis.dlike === 1 ? 'bi bi-hand-thumbs-down-fill' : 'bi bi-hand-thumbs-down'}`}></i> {dlike ? dlike : '0'}
                                                                                        </small>
                                                                                    </button>
                                                                                </div>

                                                                            </div>

                                                                        </>
                                                                    ) : (
                                                                        <div className='col-6 col-lg-3 d-flex align-items-center justify-content-end'>
                                                                            <div className='btn-secondary w-100 rounded-5  d-flex align-items-center justify-content-around'>
                                                                                <button onClick={() => navigate('/login')} type='button' className='btn btn-secondary text-light rounded-5 border-0'>
                                                                                    <small>
                                                                                        <i className="bi bi-hand-thumbs-up"></i> {like ? like : '0'}
                                                                                    </small>
                                                                                </button>
                                                                                <span>|</span>
                                                                                <button onClick={() => navigate('/login')} type='button' className='btn btn-secondary btn-secondary text-light rounded-5 border-0'>
                                                                                    <small>
                                                                                        <i className="bi bi-hand-thumbs-down"></i> {dlike ? dlike : '0'}
                                                                                    </small>
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                    }
                                                                    <div className='col-6 col-lg-3 d-flex justify-content-end align-items-center'>
                                                                        <button type='button' className='btn btn-secondary text-light rounded-5 px-4' onClick={() => handleShareViaAPI(videoData.uniqId)}>
                                                                            <i className='class="bi bi-share'></i> <small> Share</small>
                                                                        </button>

                                                                        {/*<div className="dropdown">
                                                                    <Link href="#" className="caret-none  text-decoration-none dropdown-toggle rounded-dots btn btn-secondary text-light" >
                                                                     data-bs-toggle="dropdown" aria-expanded="false" 
                                                                        <i class="bi bi-three-dots text-light"></i>
                                                                    </Link>
                                                                     <ul className="dropdown-menu">
                                                                        <li><Link className="dropdown-item" href="/login"><i class="bi bi-bar-chart-steps me-3"></i>Add to queue</Link></li>
                                                                        <li><Link className="dropdown-item" href="/login"><i className="me-3 bi bi-clock-history"></i> Save to Watch Later</Link></li>
                                                                        <li><Link className="dropdown-item" href="/login"><i class="bi bi-plus-square me-3"></i> Save to Playlist</Link></li>
                                                                        <li><Link className="dropdown-item" href="/login"><i class="bi bi-download me-3"></i> Download</Link></li>
                                                                        <li><Link className="dropdown-item" href="/login"><i className="me-3 bi bi-share"></i> Share</Link></li>
                                                                        <li><hr className="dropdown-divider" /></li>
                                                                        <li><Link className="dropdown-item" href="#"><i className="me-3 bi bi-ban"></i>  Not Interested </Link></li>
                                                                        <li><Link className="dropdown-item" href="#"><i class="bi bi-dash-circle me-3"></i>  Don't Recomended Channel </Link></li>
                                                                        <li><Link className="dropdown-item" href="#"><i className="me-3 bi bi-flag"></i>  Report </Link></li>
                                                                    </ul>
                                                                </div> */}
                                                                    </div>
                                                                </div>
                                                            </Typography>
                                                            <Typography variant="body3" color="text.secondary" className='' style={{backgroundColor:'#f2f2f2'}}>
                                                                <div className='video-description text-dark'>
                                                                    <ReadMoreVideoDesc text={description ? description : ''} maxLength={150} />
                                                                </div>
                                                            </Typography>
                                                        </div>
                                                    </div>
                                                </div>
                                            </CardContent>
                                        </Card>
                                    </div>
                                </div>
                                {/* watch-video-comment-box end */}
                                <div className='comment-box-main my-3'>
                                    <Card className='p-3'>


                                        <div className='d-flex'>
                                            <h3 className='h5 text-black'>{allComment ? allComment.length : 0} Comments</h3>
                                            {/* <div className="dropdown ms-3">
                                        <Link href="#" className="caret-none  text-decoration-none dropdown-toggle text-theme" data-bs-toggle="dropdown" aria-expanded="false">
                                            <strong cla> <i class="bi bi-filter-left"></i> Sort By</strong>
                                        </Link>
                                        <ul className="dropdown-menu">
                                            <li><Link className="dropdown-item" href="/login">Top Comments</Link></li>
                                            <li><Link className="dropdown-item" href="/login"> Newest First</Link></li>
                                        </ul>
                                    </div> */}
                                        </div>

                                        <div className='d-flex align-items-center'>

                                            {user && user.photo ? (
                                                <img src={user.photo} className="rounded-circle img-fluid" width={50} height={50} />
                                            ) : (
                                                <div>
                                                    {user ? <div className='rounded-box-shorts btn btn-secondary text-white m-0 mt-2'><span>{user.full_name.charAt(0)} </span></div> : <img src={profilePic} className="rounded-circle  img-fluid" width={50} height={50} />}
                                                </div>
                                            )}

                                            <div class="form-floating w-100">
                                                <input type="text" id="inputComment"
                                                    value={comments}
                                                    onChange={(e) => setComments(e.target.value)}
                                                    class="form-control border-2  border-start-0 border-end-0 border-top-0 border-gray bg-transparent rounded-0" placeholder="Add a comment.." required="" />
                                                <label for="inputComment" className='text-dark'>Add a comment..</label>
                                            </div>
                                        </div>
                                        <div className='d-flex justify-content-end mt-2'>

                                            <div className=''>
                                                {token ? (
                                                    <button type='button' onClick={() => addComment(videoId)} className='btn btn-secondary text-light rounded-5 px-3 ms-2'>Comment</button>
                                                ) : (
                                                    <Link to="/login" className='btn btn-secondary text-light rounded-5 px-3 ms-2'> Comment</Link>
                                                )
                                                }

                                            </div>
                                        </div>

                                        <div className='comments-list'>



                                            {loadingcomment ? (
                                                <p>Loading Comment...</p>
                                            ) : allComment ? (
                                                allComment.map((comment, index) => (
                                                    <div className='single-comment-li'>
                                                        <div className='row g-2  mt-1'>
                                                            <div className='col-12 col-lg-12  text-light d-flex gap-2'>
                                                                <div className='commenter-thumb' >


                                                                    {comment.photo ? (
                                                                        <img src={comment.photo} className="rounded-circle  img-fluid" />
                                                                    ) : (

                                                                        <div
                                                                            className='profile-ico'
                                                                        >
                                                                            {comment.full_name.charAt(0)}
                                                                        </div>
                                                                    )}

                                                                </div>
                                                                <div className='w-100'>
                                                                    <a href='#' className='channel-name mb-0 text-dark'><small>{comment.full_name}</small></a>
                                                                    <p className=' text-dark'><small>{comment.comment}</small></p>
                                                                    {/* <div className='row justify-content-start'>
                                                            <div className='col-lg-4 '>
                                                                <div className='bg-gray d-inline-block rounded-5'>
                                                                    <button onClick={() => likeMaster(chanelId, 1)} type='button' className='btn btn-secondary text-light rounded-5 border-0'>
                                                                        <small>
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-hand-thumbs-up-fill" viewBox="0 0 16 16">
                                                                                <path d="M6.956 1.745C7.021.81 7.908.087 8.864.325l.261.066c.463.116.874.456 1.012.965.22.816.533 2.511.062 4.51a9.84 9.84 0 0 1 .443-.051c.713-.065 1.669-.072 2.516.21.518.173.994.681 1.2 1.273.184.532.16 1.162-.234 1.733.058.119.103.242.138.363.077.27.113.567.113.856 0 .289-.036.586-.113.856-.039.135-.09.273-.16.404.169.387.107.819-.003 1.148a3.163 3.163 0 0 1-.488.901c.054.152.076.312.076.465 0 .305-.089.625-.253.912C13.1 15.522 12.437 16 11.5 16H8c-.605 0-1.07-.081-1.466-.218a4.82 4.82 0 0 1-.97-.484l-.048-.03c-.504-.307-.999-.609-2.068-.722C2.682 14.464 2 13.846 2 13V9c0-.85.685-1.432 1.357-1.615.849-.232 1.574-.787 2.132-1.41.56-.627.914-1.28 1.039-1.639.199-.575.356-1.539.428-2.59z" />
                                                                            </svg> {like ? like : '0'}
                                                                        </small>
                                                                    </button>

                                                                    <span className='text-dark'>|</span>
                                                                    <button onClick={() => likeMaster(chanelId, 2)} type='button' className='btn btn-secondary text-light rounded-5 border-0'>
                                                                        <small>
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-hand-thumbs-down" viewBox="0 0 16 16">
                                                                                <path d="M8.864 15.674c-.956.24-1.843-.484-1.908-1.42-.072-1.05-.23-2.015-.428-2.59-.125-.36-.479-1.012-1.04-1.638-.557-.624-1.282-1.179-2.131-1.41C2.685 8.432 2 7.85 2 7V3c0-.845.682-1.464 1.448-1.546 1.07-.113 1.564-.415 2.068-.723l.048-.029c.272-.166.578-.349.97-.484C6.931.08 7.395 0 8 0h3.5c.937 0 1.599.478 1.934 1.064.164.287.254.607.254.913 0 .152-.023.312-.077.464.201.262.38.577.488.9.11.33.172.762.004 1.15.069.13.12.268.159.403.077.27.113.567.113.856 0 .289-.036.586-.113.856-.035.12-.08.244-.138.363.394.571.418 1.2.234 1.733-.206.592-.682 1.1-1.2 1.272-.847.283-1.803.276-2.516.211a9.877 9.877 0 0 1-.443-.05 9.364 9.364 0 0 1-.062 4.51c-.138.508-.55.848-1.012.964zM11.5 1H8c-.51 0-.863.068-1.14.163-.281.097-.506.229-.776.393l-.04.025c-.555.338-1.198.73-2.49.868-.333.035-.554.29-.554.55V7c0 .255.226.543.62.65 1.095.3 1.977.997 2.614 1.709.635.71 1.064 1.475 1.238 1.977.243.7.407 1.768.482 2.85.025.362.36.595.667.518l.262-.065c.16-.04.258-.144.288-.255a8.34 8.34 0 0 0-.145-4.726.5.5 0 0 1 .595-.643h.003l.014.004.058.013a8.912 8.912 0 0 0 1.036.157c.663.06 1.457.054 2.11-.163.175-.059.45-.301.57-.651.107-.308.087-.67-.266-1.021L12.793 7l.353-.354c.043-.042.105-.14.154-.315.048-.167.075-.37.075-.581 0-.211-.027-.414-.075-.581-.05-.174-.111-.273-.154-.315l-.353-.354.353-.354c.047-.047.109-.176.005-.488a2.224 2.224 0 0 0-.505-.804l-.353-.354.353-.354c.006-.005.041-.05.041-.17a.866.866 0 0 0-.121-.415C12.4 1.272 12.063 1 11.5 1" />
                                                                            </svg>{dlike ? dlike : '0'}
                                                                        </small>
                                                                    </button>
                                                                </div>

                                                            </div>

                                                        </div> */}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))
                                            ) : (
                                                <p style={{ color: 'white' }}>No Comment available.</p>
                                            )}



                                        </div>
                                    </Card>
                                </div>
                                {/* comment-box-main end */}
                            </>


                        </div>
                        <div className='col-md-4'>
                            <div className='row'>

                                <div className='col-lg-12'>

                                    {loadingvideoss ? (
                                        <RelatedVideoLoading />
                                    ) : relatedVideos && relatedVideos.length > 0 ? (
                                        relatedVideos.map((video, index) => (
                                            <div className='video-box-single-h' onClick={() => viewVideo(video.videoId)}>

                                                <Card>
                                                    <CardContent>
                                                        <div className='row g-2'>
                                                            <div className='col-5'>
                                                                <div className='video-box'>
                                                                    <Link to={VideoRoute(video.uniqId)}>
                                                                        {/*--<span className='duration-time'>11:11:11</span>--*/}
                                                                        <div >
                                                                            <CardMedia
                                                                                component="video"
                                                                                alt="Video Poster"
                                                                                poster={video.thumbnail}
                                                                                className='w-100'
                                                                                muted
                                                                            >
                                                                                <source src={video.url} className='w-100' type="video/mp4" />
                                                                            </CardMedia>
                                                                        </div>
                                                                    </Link>
                                                                </div>
                                                            </div>
                                                            <div className='col-7'>
                                                                <div className='content-box'>
                                                                    <div className='row'>
                                                                        <div className='col-12'>
                                                                            <Typography variant="h5" component="div" className='v-tiltle'>
                                                                                <Link to={VideoRoute(video.uniqId)}>{video.title}</Link>
                                                                            </Typography>
                                                                            <Typography variant="body2" color="text.secondary">
                                                                                <Link to={'/@' + video.handel} className='mb-0 text-light small'><small>@{video.channelName}</small></Link><br />
                                                                                <Link to={VideoRoute(video.uniqId)} className='views-time'>{formatViewsCount(video.view)} views <span>.</span> {<TimeAgo date={video.createdAt} />}</Link>
                                                                            </Typography>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </CardContent>
                                                </Card>
                                            </div>
                                        ))
                                    ) : (
                                        <></>
                                    )}

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <VideoNotAvailable />
            )}
        </>
    )
}
export default WatchSinglePage
