import React, { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import Sidebar from "../Components/Includes/Sidebar";
import Header from "../Components/Includes/Header";
import SidebarIcon from "../Components/Includes/SidebarIcon";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { authHttps, getUser } from "../auth/AuthUser";

function VideoCreater() {
    const http = authHttps(); // Authentication call
    let userData = getUser(); // Getting user data

    // Sidebar states
    const [isSidebarCollapsed, setSidebarCollapsed] = useState(false);
    const [sidebarWidth, setSidebarWidth] = useState(280);
    const [sidebarOpen, setSidebarOpen] = useState(false);

    const [filterText, setFilterText] = useState(''); // Search input text
    const [creatorMaster, setCreatorMaster] = useState([]); // Main data state
    const [loading, setLoading] = useState(false); // Loading state
    const [totalEarningsSum, setTotalEarningsSum] = useState(0); // Total earnings state

    // Fetch data on component mount
    useEffect(() => {
        fetchCreatorData();
    }, []);

    // Fetch video creators data
    const fetchCreatorData = async () => {
        setLoading(true); // Show loading state
        try {
            const response = await http.post('user/allVideoAds');
            const result = response.data;
            if (result && result.status === 1 && Array.isArray(result.data)) {
                setCreatorMaster(result.data);
                const total = result.data.reduce((acc, item) => acc + (item.totalEarnings || 0), 0);
                setTotalEarningsSum(total); 
                console.log(result.data);
            } else {
                toast.error('Failed to fetch video creators.');
            }
        } catch (error) {
            console.error('Error fetching video creators:', error);
            toast.error('Error fetching video creators.');
        } finally {
            setLoading(false);
        }
    };

    // Filter items based on search text
    const filteredItems = creatorMaster.filter(item =>
        item.videoName && item.videoName.toLowerCase().includes(filterText.toLowerCase())
    );

    // Handle search input change
    const handleSearch = (event) => {
        setFilterText(event.target.value);
    };

    // Columns definition for the DataTable
    const columns = [
        {
            name: 'S. No.',
            selector: (row, index) => index + 1, 
            sortable: false,
            width: '70px',
        },
        {
            name: 'Video Name',
            selector: row => row.videoName || '-', 
            sortable: true,
        },
        {
            name: 'Channel Name',
            selector: row => row.channelName || '-', 
            sortable: true,
        },
        {
            name: 'Total Earnings',
            selector: row => row.totalEarnings || '-', 
            sortable: true,
        },
    ];

    // Toggle Sidebar
    const toggleSidebar = () => {
        setSidebarOpen(!sidebarOpen);
        setSidebarCollapsed(!isSidebarCollapsed);
        setSidebarWidth(isSidebarCollapsed ? 280 : 80); // Toggle sidebar width
    };

    return (
        <>
            <Header toggleSidebar={toggleSidebar} />
            <div className="col-xxl-2 col-xl-3 col-lg-3 display991" style={{ width: `${sidebarWidth}px` }}>
                {sidebarOpen ? (
                    <SidebarIcon onClick={toggleSidebar} />
                ) : (
                    <Sidebar onClick={toggleSidebar} />
                )}
            </div>
            <div className="col-xxl-9 col-xl-9 col-lg-9" style={{ width: isSidebarCollapsed ? '1266px' : 'calc(100% - 280px)' }}>
                <div className="content-registration pt-3 bg-dark border">
                    <div className="container">
                        <div className="p-1">
                            <div className="p-1">
                                <div className='row g-3'>
                                    <div className="col-lg-12">
                                        <div className='d-flex justify-content-spacebetween'>
                                            <h4 className="mb-2 ">Earnings Report</h4>
                                            <span style={{ marginLeft: 'auto' }}>
                                                <strong>Total Earning: <span className="badge bg-primary rounded-pill fs-5">₹{totalEarningsSum}.00</span>
                                                </strong>
                                            </span>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="col-lg-12">
                                        <div className="mb-3 d-flex justify-content-between">
                                            <input
                                                type="text"
                                                placeholder="Search by Video Name"
                                                value={filterText}
                                                onChange={handleSearch}
                                                className="form-control"
                                                style={{ width: '300px' }}
                                            />
                                        </div>
                                        {loading ? (
                                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '300px' }}>
                                                <p>Loading...</p>
                                            </div>
                                        ) : (
                                            <DataTable
                                                columns={columns}
                                                data={filteredItems}
                                                pagination
                                                highlightOnHover
                                                defaultSortField="videoName"
                                                responsive
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </>
    );
}

export default VideoCreater;
